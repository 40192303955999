<template>
  <table-view
    ref="tableView"
    url="/dispatch/taskPatrol/statistics"
    :filterable="false"
    :filter-form="dataForm"
    :headers="headers"
    show-index
    single
  >
    <el-form slot="search" @keyup.enter.native="query" inline>
      <el-form-item label="开始日期">
        <el-date-picker type="month" v-model="dataForm.beginBillingDate" value-format="yyyy-MM"></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker type="month" v-model="dataForm.endBillingDate" value-format="yyyy-MM"></el-date-picker>
      </el-form-item>
      <el-select filterable clearable v-model="dataForm.baseId" placeholder="基地">
        <el-option
          v-for="s in baseList"
          :key="s.id"
          :label="s.name"
          :value="s.id"
        ></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'report-patrolStatistics',

  components: { TableView },

  data () {
    return {
      baseList: [],
      headers: [
        { id: 1, prop: 'departmentName', label: '基地名称', width: 100 },
        { id: 2, prop: 'billingDate', label: '周期', width: 120 },
        { id: 3, prop: 'siteNumber', label: '开工工地总数', width: 100 },
        { id: 4, prop: 'patrolSiteNumber', label: '巡查工地总数', width: 100 },
        {
          id: 5,
          prop: 'patrolSiteRatio',
          label: '巡查工地占比',
          width: 100,
          formatter: (row, column, cellValue) => {
            return (cellValue * 100).toFixed(2) + '%'
          }
        },
        { id: 6, prop: 'patrolNumber', label: '巡查总数', width: 100 },
        { id: 7, prop: 'patrolPassedNumber', label: '巡查通过数', width: 100 },
        { id: 8, prop: 'patrolFailedNumber', label: '巡查不通过数', width: 100 },
        {
          id: 9,
          prop: 'patrolPassedRatio',
          label: '巡查通过率',
          width: 100,
          formatter: (row, column, cellValue) => {
            return (cellValue * 100).toFixed(2) + '%'
          }
        },
        { id: 10, prop: 'onLinePatrolNumber', label: '线上巡查总数', width: 100 },
        { id: 11, prop: 'onLinePatrolSiteNumber', label: '线上巡查工地数', width: 120 },
        { id: 12, prop: 'onLinePatrolPassedNumber', label: '线上巡查通过数', width: 120 },
        { id: 13, prop: 'onLinePatrolFailedNumber', label: '线上巡查不通过数', width: 120 },
        {
          id: 14,
          prop: 'onLinePatrolPassedRatio',
          label: '线上巡查通过率',
          width: 120,
          formatter: (row, column, cellValue) => {
            return (cellValue * 100).toFixed(2) + '%'
          }
        },
        { id: 15, prop: 'offLinePatrolNumber', label: '线下巡查总数', width: 100 },
        { id: 16, prop: 'offLinePatrolSiteNumber', label: '线下巡查工地数', width: 120 },
        { id: 17, prop: 'offLinePatrolPassedNumber', label: '线下巡查通过数', width: 120 },
        { id: 18, prop: 'offLinePatrolFailedNumber', label: '线下巡查不通过数', width: 120 },
        {
          id: 19,
          prop: 'offLinePatrolPassedRatio',
          label: '人工巡查通过率',
          width: 120,
          formatter: (row, column, cellValue) => {
            return (cellValue * 100).toFixed(2) + '%'
          }
        }
      ],
      dataForm: {
        beginBillingDate: '',
        endBillingDate: '',
        baseId: ''
      }
    }
  },

  methods: {
    query () {
      if (this.dataForm.beginBillingDate == null || this.dataForm.beginBillingDate === '') {
        this.$message.error('开始时间不能为空')
        return
      }
      this.$refs.tableView.queryData()
    }
  },

  async created () {
    var day = new Date()
    day.setTime(day.getTime())
    let month = (day.getMonth() + 1) > 9 ? (day.getMonth() + 1) : '0' + (day.getMonth() + 1)
    this.dataForm.beginBillingDate = day.getFullYear() + '-' + month
    const data = await this.$http({
      url: this.$http.adornUrl('/user/base/list'),
      method: 'post'
    })
    this.baseList = data.datas
  }
}
</script>
